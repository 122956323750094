import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import VeeValidate, { Validator } from 'vee-validate';
import br from 'vee-validate/dist/locale/pt_BR';
import { getPedidos, getVisitante, postVisitante } from '../api';
import { resetOrder } from '../modules/order';
import { resetCart } from '../modules/cart';
import { addToken, resetUser } from '../modules/user';
import { alerta } from '../utils/alerta';

Vue.use(VeeValidate, { events: 'change' });
Vue.use(VueTheMask);

const dictionary = {
    pt_BR: {
        messages: {
            date_format: () => 'Data inválida.',
            required: () => 'Preenchimento obrigatório.'
        }
    }
};

// altera idioma padrão das mensagens para pt-BR;
Validator.localize('pt_BR', br);
// sobrescreve e concatena as mensagens personalizadas;
Validator.localize(dictionary);

const pedidos = new Vue({
    el: '#pedidos',
    map: state => ({
        token: state.user.token
    }),
    actions: {
        getPedidos,
        resetOrder,
        resetCart,
        postVisitante,
        getVisitante,
        resetUser,
        addToken
    },
    data: {
        token: null,
        pedidos: {},
        telefone: null,
        email: null,
        enviando: false
    },
    computed: {
        isLogado() {
            return this.token ? true : false;
        },
        listaPedidos() {
            let total = Object.keys(this.pedidos).length;
            if (this.token && !total) {
                getPedidos(this.token)
                    .then(pedidos => {
                        this.pedidos = pedidos.data;
                    })
                    .catch(() => {
                        this.resetOrder();
                        this.resetCart();
                        window.location = '/delivery';
                    });
            } else if (total) return this.pedidos;
            else return {};
        }
    },
    methods: {
        buscaCliente() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    if (!this.enviando) {
                        this.enviando = true;
                        if (this.telefone) {
                            getVisitante(this.telefone)
                                .then(result => {
                                    const dados = {
                                        telefone: result.telefone,
                                        email: result.email,
                                        nome: result.nome
                                    };

                                    if (this.email == result.email) {
                                        this.login(dados);
                                    } else {
                                        alerta('erro', 'Desculpe, não foi possível verificar a sua conta.');
                                    }
                                    this.enviando = false;
                                })
                                .catch(() => {
                                    alerta(
                                        'erro',
                                        'Desculpe, não encontramos nenhum cliente.'
                                    );
                                    this.enviando = false;
                                });
                        }
                    }
                } else {
                    alerta('erro', 'Preencha todos os campos obrigatórios.');
                }
            });
        },
        login(dados) {
            postVisitante(dados).then(result => {
                this.resetUser();
                this.addToken(result.token);
            });
        }
    }
});

export default pedidos;
