import Vue from 'vue';
import Hammer from 'hammerjs';

const sCardapio = new Vue({
    el: '#s-cardapio',
    data: {
        step: 1,
        max: 4
    },
    methods: {
        next() {
            this.step += 1;
            if (this.step > this.max) {
                this.step = 1;
            }
        },
        prev() {
            this.step -= 1;

            if (this.step == 0) {
                this.step = this.max;
            }
        },
        changeStep(n) {
            this.step = n;
        }
    }
});

const cardapioEl = document.querySelector('#s-cardapio');
if(cardapioEl) {
    const gesture = new Hammer(cardapioEl);

    gesture.on('swipeleft swiperight', evt => {
        if (window.innerWidth < 1023) {
            switch(evt.type) {
            case 'swipeleft':
                sCardapio.next();
                break;
            case 'swiperight':
                sCardapio.prev();
                break;
            }
        }
    });
}

export default sCardapio;
