import Vue from 'vue';
import { addProduct, removeProduct } from '../modules/cart';

Vue.filter('currency', function(value) {
    if (!value) return 'R$ 0,00';
    return (
        'R$ ' +
        parseFloat(value)
            .toFixed(2)
            .replace('.', ',')
    );
});

Vue.filter(situacao(), function(value) {
    if (value == 0) return { classe: 'cancelado', titulo: 'Cancelado' };
    if (value == 1) return { classe: 'pendente', titulo: 'Pendente' };
    if (value == 2) return { classe: 'aprovado', titulo: 'Finalizado' };
    if (value == 3) return { classe: 'pendente', titulo: 'Saiu Para entrega' };
    if (value == 5) return { classe: 'pendente', titulo: 'Em Produção' };
});

Vue.filter('pagamento', function(value) {
    if (value == 0) return 'Dinheiro';
    if (value == 1) return 'Cartão de crédito/débito';
});

Vue.filter('cartao', function(value) {
    switch (value) {
    case 'Visa':
        return 'visa';
    case 'Master':
        return 'master';
    case 'American Express':
        return 'amex';
    case 'Diners Club':
        return 'diners';
    case 'Hipercard':
        return 'hipercard';
    case 'Elo':
        return 'elo';
    default:
        return '';
    }
});

const delivery = new Vue({
    el: '#delivery',
    map: state => ({
        products: state.cart.products,
        counter: state.cart.counter
    }),
    actions: { addProduct, removeProduct },
    data: {
        products: {},
        counter: 0,
        menu: '',
        categoryIndex: 0
    },
    methods: {
        adicionaNoCarrinho(id) {
            if (!this.products[id]) {
                this.addProduct(id);
            } else {
                this.removeProduct(id);
            }
        }
    }
});

const fixedHeader = () => {
    if (window.pageYOffset > 0) {
        delivery.menu = 'delivery__categoria--active';
    } else {
        delivery.menu = '';
    }
};

window.onscroll = function() {
    fixedHeader();
};

export default delivery;
function situacao() {
    return 'situacao';
}
