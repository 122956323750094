import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import persistState from 'redux-localstorage';

import cart from './modules/cart';
import order from './modules/order';
import user from './modules/user';
import lojas from './modules/lojas';

const reducer = combineReducers({
    cart,
    order,
    user,
    lojas
});

const middlewares = compose(
    applyMiddleware(ReduxThunk),
    persistState(),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
);

const store = createStore(reducer, middlewares);

export default store;
