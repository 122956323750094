import Vue from 'vue';
import { nextstep, changeStep } from '../modules/order';
import { resetUser } from '../modules/user';

const steps = new Vue({
    el: '#steps',
    map: state => ({
        step: state.order.step,
        token: state.user.token,
        counter: state.cart.counter
    }),
    actions: {
        nextstep,
        changeStep,
        resetUser
    },
    data: {
        step: null,
        token: null,
        counter: 0
    },
    methods: {
        onNextStep() {
            this.nextstep();
        },
        onChangeStep(step) {
            this.changeStep(step);
        }
    },
    created() {
        setTimeout(() => {
            const loading = document.querySelector('.checkout__loading');

            if(loading) {
                loading.classList.remove('checkout__loading--is-active');
            }
        }, 500);
    },
    updated() {
        if (!this.token) this.changeStep(1);

        if (!this.counter) {
            window.location = '/delivery';
        }
    }
});

export const headerCheckout = new Vue({
    el: '#header-checkout',
    map: state => ({
        step: state.order.step
    }),
    actions: {
        changeStep,
    },
    data: {
        step: 0
    },
    methods: {
        back(evt) {
            if(this.step > 1) {
                evt.preventDefault();
                this.changeStep(this.step - 1);
            }
        }
    }
});

export default steps;
