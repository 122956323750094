import axios from 'axios';

//lista de procutos
export const getProductById = id => new Promise((resolve, reject) => {
    axios.get(`/api/produto/${id}`)
        .then(res => {
            const {
                titulo,
                imagems,
                preco,
                preco_promocional,
                descricao,
            } = res.data;

            const result = {
                id: Array.isArray(id) ? id[0] : id,
                titulo,
                imagems,
                descricao,
                preco: parseFloat(preco),
                preco_promocional: preco_promocional !== null ? parseFloat(preco_promocional) : null,
                preco_formatado: `R$ ${parseFloat(preco).toFixed(2).replace('.', ',')}`,
                preco_promo_formatado: `R$ ${parseFloat(preco_promocional).toFixed(2).replace('.', ',')}`,
                observacao: '',
                quantidade: 1
            };

            resolve(result);
        })
        .catch(err => {
            reject(err);
        });
});

// busca o valor do cep
export const getValorCep = cep => new Promise((resolve, reject) => {
    axios.get(`/api/frete/calcular/${cep}`)
        .then(res => {

            var {
                id,
                titulo,
                valor,
            } = res.data.data[0];

            if(id == 1)
            {
                var {
                    id,
                    titulo,
                    valor,
                } = res.data.data[1];
            }

            const result = {
                id: id,
                titulo: titulo,
                valor: parseFloat(valor),
                valor_formatado: `R$ ${parseFloat(valor).toFixed(2).replace('.', ',')}`
            };

            resolve(result);
        })
        .catch(err => {
            reject(err);
        });
});

// busca o horario do frete escolhido
export const getFretePrevisao = frete => new Promise((resolve, reject) => {
    axios.get(`/api/horario/frete/${frete}`)
        .then(res => {

            var {
                aberto,
                previsao
            } = res.data.data;

            const result = {
                aberto: aberto,
                previsao: previsao
            };

            resolve(result);
        })
        .catch(err => {
            reject(err);
        });
});

//cadastra e/ou faz login de visitante existente
export const postVisitante = dados => new Promise((resolve, reject) => {
    axios.post('/api/visitante',dados)
        .then(res => {

            const {
                cliente,
                token
            } = res.data.data;

            const result = {
                token: token,
                user: cliente
            };

            resolve(result);
        })
        .catch(err => {
            reject(err);
        });
});

//faz login de visitante existente
export const getVisitante = termo => new Promise((resolve, reject) => {
    axios.get(`/api/visitante/${termo}`)
        .then(res => {
            resolve(res.data.data);
        })
        .catch(reject);
});

//faz login de visitante existente
export const postAuthVisitante = email => new Promise((resolve, reject) => {
    axios.post('/api/auth/visitante',{email:email})
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err);
        });
});

//edita dados do visitante
export const putVisitante = (dados) => new Promise((resolve, reject) => {
    axios.put(`/api/visitante/${dados.id}`,dados)
        .then(res => {
            const {
                cliente,
                token
            } = res.data.data;

            const result = {
                token: token,
                user: cliente
            };

            resolve(result);
        })
        .catch(err => {
            reject(err);
        });
});

//busca dados do logado
export const getLogado = token => new Promise((resolve, reject) => {
    axios.get('/api/cliente',{
        headers: {
            'Authorization': 'Bearer '+token,
        }
    })
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err);
        });
});

// salva informações de contato
export const postContato = dados => new Promise((resolve, reject) => {
    axios.post('/api/contato', dados)
        .then(res => {
            const {
                message
            } = res.data.message;

            resolve(message);
        })
        .catch(err => {
            reject(err);
        });
});

// salva informações de evento
export const postEvento = dados => new Promise((resolve, reject) => {
    axios.post('/api/faca-seu-evento', dados)
        .then(res => {
            const {
                message
            } = res.data.message;

            resolve(message);
        })
        .catch(err => {
            reject(err);
        });
});

//busca endereços do cliente logado
export const getEnderecos = token => new Promise((resolve, reject) => {
    axios.get('/api/endereco/logado',{
        headers: {
            'Authorization': 'Bearer '+token,
        }
    })
        .then(res => {

            resolve(res.data);
        })
        .catch(err => {
            reject(err);
        });
});



//salva novo endereço do cliente logado
export const postEndereco = (endereco,token) => new Promise((resolve, reject) => {
    //Adiciona titulo para não cadastrar como endereço oculto
    endereco.titulo = endereco.bairro;
    axios.post('/api/endereco',endereco,{
        headers: {
            'Authorization': 'Bearer '+token,
        }
    })
        .then(res => {
            const {
                bairro,
                cep,
                cidade,
                id,
                logradouro,
                numero,
                uf,
                referencia,
                complemento
            } = res.data.data;

            const result = {
                id : id,
                cep : cep,
                bairro: bairro,
                logradouro: logradouro,
                numero: numero,
                cidade: cidade,
                uf: uf,
                complemento: complemento,
                referencia:referencia
            };

            resolve(result);
        })
        .catch(err => {
            reject(err);
        });
});

//busca endereços do cliente logado
export const getLojas = () => new Promise((resolve, reject) => {
    axios.get('/api/loja')
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err);
        });
});

export const getFretes = () => new Promise((resolve, reject) => {
    axios.get('/api/frete')
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err);
        });
});

//Salva novo pedido
export const postPedido = (pedido, token) => new Promise((resolve, reject) => {
    axios.post('/api/pedido',pedido,{
        headers: {
            'Authorization': 'Bearer '+token,
        }
    })
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err);
        });
});

//Busca pedidos do cliente logado
export const getPedidos = token => new Promise((resolve, reject) => {
    axios.get('/api/pedido',{
        headers: {
            Authorization: 'Bearer '+token,
        }
    })
        .then(res => {

            resolve(res.data);
        })
        .catch(err => {
            reject(err);
        });
});
