export const CHANGE_USER = 'order/CHANGE_USER';
export const CHANGE_ADDRESS = 'order/CHANGE_ADDRESS';
export const CHANGE_SHIPPING = 'order/CHANGE_SHIPPING';
export const CHANGE_PAYMENT = 'order/CHANGE_PAYMENT';
export const NEXT_STEP = 'order/NEXT_STEP';
export const CHANGE_STEP = 'order/CHANGE_STEP';
export const ADD_TOKEN = 'order/ADD_TOKEN';
export const RESET_ORDER = 'order/RESET_ORDER';


const INIT = {
    user: {},
    address: {},
    shipping : {},
    payment: {},
    step : 1
};

export default (state = INIT, action) => {
    switch(action.type) {
        case CHANGE_USER:
            return {
                ...state,
                user: action.payload
            };
        case CHANGE_ADDRESS:
            return {
                ...state,
                address: action.payload
            };
        case CHANGE_SHIPPING:
            return {
                ...state,
                shipping: action.payload
            };
        case CHANGE_PAYMENT:
            return {
                ...state,
                payment: action.payload
            };
        case NEXT_STEP:
            return {
                ...state,
                step: (state.step += 1)
            };
        case CHANGE_STEP:
            return {
                ...state,
                step: action.payload
            };
        case ADD_TOKEN:
            return {
                ...state,
                token: action.payload
            };
        case RESET_ORDER:
            return INIT;
        default:
            return state;
        }
};

export const nextstep = () => dispatch => {
    window.scrollTo({ top: 0 });

    dispatch({ type: NEXT_STEP });
};

export const changeStep = step => dispatch => {
    window.scrollTo({ top: 0 });

    dispatch({
        type: CHANGE_STEP,
        payload: step
    });
};

export const changeUser = (user) => ({
    type: CHANGE_USER,
    payload: user
});

export const changeAddress = (address) => ({
    type: CHANGE_ADDRESS,
    payload: address
});

export const changeShipping = (shipping) => ({
    type: CHANGE_SHIPPING,
    payload: shipping
});

export const changePayment = (payment) => ({
    type: CHANGE_PAYMENT,
    payload: payment
});

export const addToken = (token) => ({
    type: ADD_TOKEN,
    payload: token
});

export const resetOrder = () => ({ type: RESET_ORDER });
