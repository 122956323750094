import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import axios from 'axios';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import {
    incrementProduct,
    decrementProduct,
    removeProduct,
    changeObservation
} from '../modules/cart';
import { changeAddress, changeShipping, changeStep } from '../modules/order';
import { addAddress, resetUser } from '../modules/user';
import { getValorCep } from '../api';

Vue.use(VueTheMask);

const carrinho = new Vue({
    el: '#carrinho',
    map: state => ({
        token: state.user.token,
        addresses: state.user.addresses,
        products: state.cart.products,
        counter: state.cart.counter,
        shipping: state.order.shipping,
        address: state.order.address
    }),
    actions: {
        incrementProduct,
        decrementProduct,
        removeProduct,
        changeObservation,
        changeShipping,
        resetUser,
        addAddress,
        changeAddress,
        changeStep
    },
    data: {
        token: null,
        products: {},
        counter: 0,
        shipping: {},
        address: {},
        addresses: {},
        erro: false,
        cepIsLoading: false,
        usarCartaoFidelidade: false,
    },
    computed: {
        subtotal() {
            return reduce(
                filter(this.products, o => !!o),
                (subtotal, { preco, preco_promocional, quantidade }) => {
                    const precoReal = preco_promocional
                        ? preco_promocional
                        : preco;
                    return subtotal + precoReal * quantidade;
                },
                0.0
            );
        },
        receberNoLocalChecked() {
            return this.shipping.id == 1;
        }
    },
    methods: {
        onMenusClick(id) {
            this.decrementProduct(id);
        },
        onMaisClick(id) {
            this.incrementProduct(id);
        },
        onTrashClick(id) {
            this.removeProduct(id);
        },
        onObservationInput(id, event) {
            this.changeObservation(id, event.target.value);
        },
        onCalculaCEP() {
            this.cepIsLoading = true;

            getValorCep(this.address.cep)
                .then(frete => {
                    this.cepIsLoading = false;
                    this.changeShipping(frete);
                    this.buscaCep(this.address.cep);
                    this.erro = false;
                })
                .catch(err => {
                    this.cepIsLoading = false;
                    this.changeShipping({});
                    this.changeAddress({});
                    this.erro = true;
                });
        },
        buscaCep(cep) {
            if (cep) {
                axios
                    .get(`https://viacep.com.br/ws/${cep}/json/`)
                    .then(res => {
                        const data = res.data;

                        const endereco = {
                            cep: data.cep,
                            logradouro: data.logradouro,
                            bairro: data.bairro,
                            cidade: data.localidade,
                            uf: data.uf
                        };

                        this.changeAddress(endereco);
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        },
        receberNoLocal(event) {
            if (event.target.checked) {
                const frete = {
                    id: 1,
                    titulo: 'Retirar no local',
                    valor: 0
                };
                this.changeShipping(frete);
                this.changeAddress({});
            } else {
                this.changeShipping({});
                this.changeAddress({});
            }
        },
        onComprar(el) {
            if (this.shipping.id) {
                this.changeStep(1);
                window.location = el.currentTarget.getAttribute('href');
            }
        }
    }
});

export default carrinho;
