import Vue from 'vue';

const header = new Vue({
    el: '#header',
    map: state => {
        return {
            productsCounter: state.cart.counter
        };
    },
    data: {
        isMenuOpen: false,
        productsCounter: 0
    },
    methods: {
        openMenu() {
            this.isMenuOpen = true;
        },
        closeMenu() {
            this.isMenuOpen = false;
        }
    }
});

const menuContainer = document.querySelector('.menu__container');
if(menuContainer) {
    menuContainer.addEventListener('click', e => {
        if(e.target.classList.contains('container')) {
            header.closeMenu();
        }
    });
}

window.addEventListener('keydown', e => (e.keyCode ? e.keyCode : e.which) === 27 ? header.closeMenu() : null);

// if ( window.innerWidth > 1024 ) {
//     const menuLink = document.querySelectorAll('.menu__link');
//     menuLink.forEach('touchstart', e => {
//         e.preventDefault();
//         window.location.href = menuLink.href;
//     })
// }

const headerEl = document.querySelector('.header');

if (headerEl) {
    const fixedHeader = () => {
        if (window.pageYOffset > 0) {
            headerEl.classList.add('header--fixed');
        } else {
            headerEl.classList.remove('header--fixed');
        }
    };

    window.addEventListener('scroll', fixedHeader);
}

export default header;
