export const ADD_TOKEN = 'user/ADD_TOKEN';
export const ADD_ADDRESS = 'user/ADD_ADDRESS';
export const RESET_USER = 'user/RESET_USER';


const INIT = {
    token: null,
    addresses: {},
};

export default (state = INIT, action) => {
    switch(action.type) {
        case ADD_TOKEN:
            return {
                ...state,
                token: action.payload
            };
        case ADD_ADDRESS:
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    [action.payload.id]: action.payload
                }
            };
        case RESET_USER:
            return INIT;
        default:
            return state;
        }
};

export const addToken = (token) => ({
    type: ADD_TOKEN,
    payload: token
});

export const addAddress = (address) => ({
    type: ADD_ADDRESS,
    payload: address
});

export const resetUser = () => ({ type: RESET_USER });