import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import VeeValidate, { Validator } from 'vee-validate';
import br from 'vee-validate/dist/locale/pt_BR';
import { nextstep, changeUser } from '../modules/order';
import { addToken, addAddress, resetUser } from '../modules/user';
import { addLoja } from '../modules/lojas';
import {
    postVisitante,
    getEnderecos,
    getVisitante,
    postAuthVisitante,
    putVisitante,
    getLojas
} from '../api';
import { alerta } from '../utils/alerta';

Vue.use(VeeValidate, { events: 'change' });
Vue.use(VueTheMask);

const dictionary = {
    pt_BR: {
        messages: {
            date_format: () => 'Data inválida.',
            required: () => 'Preenchimento obrigatório.'
        }
    }
};

// altera idioma padrão das mensagens para pt-BR;
Validator.localize('pt_BR', br);
// sobrescreve e concatena as mensagens personalizadas;
Validator.localize(dictionary);

const identificacao = new Vue({
    el: '#identificacao',
    map: state => ({
        token: state.user.token,
        step: state.order.step,
        user: state.order.user
    }),
    actions: {
        nextstep,
        changeUser,
        addToken,
        resetUser,
        addAddress,
        addLoja
    },
    data: {
        token: null,
        user: {},
        enviando: false,
        step: null,
        confirmacao: false,
        fb: {},
        fbSemTelefone: false
    },
    methods: {
        onNextStep(dados) {
            this.resetUser();
            this.addToken(dados.token);
            this.changeUser(dados.user);
            this.buscaEnderecos(dados.token);
            this.buscaLojas();
            this.nextstep();
        },
        buscaLojas() {
            getLojas().then(lojas => {
                lojas.forEach(function(loja) {
                    identificacao.addLoja(loja);
                });
            });
        },
        validateBeforeSubmit() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    if (!this.enviando) {
                        this.enviando = true;
                        const dados = {
                            telefone: this.user.telefone,
                            email: this.user.email,
                            nome: this.user.nome
                        };

                        postVisitante(dados)
                            .then(result => {
                                this.onNextStep(result);
                                this.enviando = false;
                            })
                            .catch(() => {
                                alerta(
                                    'erro',
                                    'Desculpe, ocorreu um erro, tente novamente mais tarde.'
                                );
                                this.enviando = false;
                            });
                    }
                    return;
                }

                alerta('erro', 'Preencha todos os campos obrigatórios.');
            });
        },
        buscaEnderecos(token) {
            getEnderecos(token).then(addresses => {
                addresses.forEach(function(address) {
                    identificacao.addAddress(address);
                });
            });
        },
        fbCheckstatus() {
            var self = this;
            FB.getLoginStatus(function(response) {
                if (response.status == 'connected') {
                    self.fbDados();
                } else {
                    self.fbLogin();
                }
            });
        },
        fbLogin() {
            var self = this;
            FB.login(
                function(response) {
                    if (response.status === 'connected') {
                        self.fbDados();
                    } else {
                        alerta(
                            'erro',
                            'Você precisa aceitar as permissões do facebook para continuar.'
                        );
                    }
                },
                { scope: 'email' }
            );
        },
        fbDados() {
            var self = this;
            FB.api('/me?fields=id,name,email', function(response) {
                getVisitante(response.email)
                    .then(cliente => {
                        self.fb.facebook_id = response.id;
                        self.fb.id = cliente.id;
                        self.fb.nome = cliente.nome;
                        self.fb.email = cliente.email;
                        self.fb.telefone = cliente.telefone;
                        //se o cliente já tem o fbid igual ao login do fb só passa direto pro próximo passo
                        if (cliente.facebook_id == response.id) {
                            postAuthVisitante(self.fb.email)
                                .then(res => {
                                    let dados = {
                                        token: res.token,
                                        user: self.fb
                                    };
                                    self.onNextStep(dados);
                                })
                                .catch(() => {
                                    alerta(
                                        'erro',
                                        'Desculpe, ocorreu um erro, tente novamente mais tarde.'
                                    );
                                    this.enviando = false;
                                });
                        } //senão ele precisa confirmar os dados
                        else {
                            self.confirmacao = true;
                        }
                    })
                    .catch(() => {
                        self.fb.id = 0;
                        self.fb.facebook_id = response.id;
                        self.fb.nome = response.name;
                        self.fb.email = response.email;
                        self.confirmacao = true;
                    });
            });
        },
        fbSalvar() {
            this.fbSemTelefone = false;
            if (this.fb.telefone == '') {
                this.fbSemTelefone = true;
            } else {
                if (!this.enviando) {
                    this.enviando = true;
                    //se for um cliente novo
                    if (!this.fb.id) {
                        postVisitante(this.fb)
                            .then(result => {
                                this.onNextStep(result);
                                this.enviando = false;
                            })
                            .catch(() => {
                                alerta(
                                    'erro',
                                    'Desculpe, ocorreu um erro, tente novamente mais tarde.'
                                );
                                this.enviando = false;
                            });
                    } //se for uma edição
                    else {
                        putVisitante(this.fb)
                            .then(result => {
                                this.onNextStep(result);
                                this.enviando = false;
                            })
                            .catch(() => {
                                alerta(
                                    'erro',
                                    'Desculpe, ocorreu um erro, tente novamente mais tarde.'
                                );
                                this.enviando = false;
                            });
                    }
                }
            }
        }
    }
});

export default identificacao;
