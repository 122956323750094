import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import VeeValidate from 'vee-validate';
import money from 'v-money';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import { nextstep, changePayment } from '../modules/order';
import { alerta } from '../utils/alerta';

Vue.use(VeeValidate, { events: 'change' });
Vue.use(VueTheMask);
Vue.use(money, { precision: 2 });

const pagamento = new Vue({
    el: '#pagamento',
    map: state => ({
        step: state.order.step,
        payment: state.order.payment,
        shipping: state.order.shipping,
        products: state.cart.products
    }),
    actions: {
        nextstep,
        changePayment
    },
    data: {
        step: 1,
        products: {},
        shipping: {},
        payment: {},
        troco: 0,
        money: {
            decimal: ',',
            thousands: '.',
            prefix: 'R$ ',
            suffix: '',
            precision: 2,
            masked: false
        }
    },
    computed: {
        subtotal() {
            return reduce(
                filter(this.products, o => !!o),
                (subtotal, { preco, preco_promocional, quantidade }) => {
                    const precoReal = preco_promocional
                        ? preco_promocional
                        : preco;
                    return subtotal + precoReal * quantidade;
                },
                0.0
            );
        },
        total() {
            return this.subtotal + this.shipping.valor;
        }
    },
    methods: {
        onNextStep() {
            this.nextstep();
        },
        onChangeCartao(cartao) {
            let payment = {
                tipo: 'cartao',
                cartao: cartao
            };
            this.changePayment(payment);
        },
        selectCartao(id) {
            let payment = {
                id: id,
                troco: 0,
                titulo: 'Cartão de Crédito/Débito',
                tipo: 'cartao',
                cartao: this.payment.cartao
            };
            this.changePayment(payment);
            this.nextstep();
        },
        selectDinheiro(id) {
            let troco_decimal = parseFloat(
                this.troco.replace('R$ ', '').replace(',', '.')
            );
            if (troco_decimal < this.total) {
                alerta('erro', 'Informe um valor superior ao total do pedido.');
            } else {
                let payment = {
                    id: id,
                    cartao: '',
                    titulo: 'Dinheiro',
                    tipo: 'dinheiro',
                    troco: troco_decimal
                };
                this.changePayment(payment);
                this.nextstep();
            }
        }
    }
});

export default pagamento;
