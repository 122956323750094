import { bindActionCreators } from 'redux';

export default store => {
    return $vm => {
        $vm.store = store;
        const sync = () => {
            const state = store.getState();
            const mappedState = $vm.$options.map(state);

            for(const key in mappedState) {
                $vm[key] = mappedState[key];
            }
        };

        store.subscribe(sync);

        const actions = $vm.$options.actions;
        for(const key in actions) {
            $vm[key] = bindActionCreators(actions[key], store.dispatch);
        }

        sync();
    }
};
