import Vue from 'vue';
import anime from 'animejs';
import Hammer from 'hammerjs';

const banner = new Vue({
    el: '#banner',
    data: {
        step: 1,
        max: 2
    },
    methods: {
        next() {
            this.step += 1;
            if (this.step > this.max) {
                this.step = 1;
            }
        },
        prev() {
            this.step -= 1;

            if (this.step == 0) {
                this.step = this.max;
            }
        },
        changeStep(n) {
            this.step = n;
        }
    }
});

const setDotPosition = () => {
    const dots = document.querySelector('.banner__pagination');
    if (dots) {
        const { x } = document.querySelector('.container').getBoundingClientRect();
        anime.set(dots, { left: x });
    }
};

setDotPosition();
window.addEventListener('resize', setDotPosition);

const nextButton = document.querySelector('.banner__next');
if(nextButton) {
    nextButton.addEventListener('click', e => {
        e.preventDefault();
        const nextSession = document.querySelector('.s-delivery');

        if(nextSession) {
            window.scrollTo({
                behavior: 'smooth',
                top: nextSession.offsetTop
            });
        } else {
            console.warn('[WARN] Next session not found.');
        }
    });
}

const bannerEl = document.querySelector('#banner');
if(bannerEl) {
    const gesture = new Hammer(bannerEl);

    gesture.on('swipeleft swiperight', evt => {
        switch(evt.type) {
        case 'swipeleft':
            banner.prev();
            break;
        case 'swiperight':
            banner.next();
            break;
        }
    });
}

export default banner;
