import Vue from 'vue';
import { resetOrder } from '../modules/order';
import { resetCart } from '../modules/cart';

const page_confirmacao = document.querySelector('#confirmacao');

if(page_confirmacao) {
    var confirmacao = new Vue({
        el: '#confirmacao',
        map: state => ({
            token: state.user.token,
            step: state.order.step,
            shipping: state.order.shipping
        }),
        data: {
            token: null,
            step: null,
            firsttime: true,
            shipping: {},
            previsao: null
        },
        actions: {
            resetOrder,
            resetCart
        },
        watch: {
            step(){
                if(this.step != 5 && this.firsttime)
                {
                    window.location = '/delivery';
                }
                else if(this.firsttime)
                {
                    let previsao = this.shipping.previsao;
                    this.previsao = previsao;

                    this.resetOrder();
                    this.resetCart();
                    this.firsttime = false;
                }

            }
        }
    });
} else {
    var confirmacao = new Vue({
        el: '#confirmacao',
        map: state => ({
            token: state.user.token
        }),
    });
}

export default confirmacao;
