import * as _ from 'underwater';

function responsive(object) {
    let expectedWindowSize = null;
    for(let prop in object) {
        expectedWindowSize = parseInt(prop);
        if (expectedWindowSize <= window.innerWidth) {
            break;
        }
    }

    return object[expectedWindowSize.toString()];
}


const scroll = slug => {
    const section = document.querySelector(`[city=${slug}]`);

    if (section) {
        section.scrollIntoView({
            behavior: 'smooth'
        });
    }
};

window.addEventListener('load', () => {
    setTimeout(() => {
        const path = window.location.pathname;
        const citySlug = path.replace('/restaurantes/', '');

        if (!citySlug.includes('/')) {
            scroll(citySlug);
        }
    }, 300);
});

const links = document.querySelectorAll('.restaurantes__item');
if (links.length) {
    Array.prototype.slice.call(links).forEach(link => {
        link.addEventListener('click', e => {
            e.preventDefault();
            window.history.pushState({}, '', link.href);

            scroll(window.location.pathname.replace('/restaurantes/', ''));
        });
    });
}

const parallaxRestaurante1 = document.querySelector('#restaurante-parallax-1');
if(parallaxRestaurante1) {
    window.addEventListener('scroll', () => {
        const moveFactor = responsive({
            '1439': .3,
            '1279': .2
        });

        const scrollFactor = window.pageYOffset;
        const containerSize = parallaxRestaurante1.getBoundingClientRect();
        const calculatePosition = _.range([0, containerSize.height], [0, containerSize.height]);
        const backgroundPosition = calculatePosition(scrollFactor) * moveFactor;

        parallaxRestaurante1.style.backgroundPosition = `center ${backgroundPosition}px`;
    });
}

const parallaxRestaurante2 = document.querySelector('#restaurante-parallax-2');
if(parallaxRestaurante2) {
    window.addEventListener('scroll', () => {
        const moveFactor = responsive({
            '1439': .132,
            '1279': .12
        });

        const scrollFactor = window.pageYOffset;
        const containerSize = parallaxRestaurante2.getBoundingClientRect();
        const calculatePosition = _.range([0, containerSize.height], [0, containerSize.height]);
        const backgroundPosition = calculatePosition(scrollFactor) * moveFactor;

        parallaxRestaurante2.style.backgroundPosition = `center ${backgroundPosition}px`;
    });
}

const parallaxRestaurante3 = document.querySelector('#restaurante-parallax-3');
if(parallaxRestaurante3) {
    window.addEventListener('scroll', () => {
        const moveFactor = responsive({
            '1439': .1,
            '1279': .085
        });

        const scrollFactor = window.pageYOffset;
        const containerSize = parallaxRestaurante3.getBoundingClientRect();
        const calculatePosition = _.range([containerSize.top, containerSize.top + containerSize.height], [0, containerSize.height]);
        const backgroundPosition = (calculatePosition(scrollFactor) * moveFactor) - 200;

        parallaxRestaurante3.style.backgroundPosition = `center ${backgroundPosition}px`;
    });
}
