import Rellax from 'rellax';

if(document.querySelectorAll('.c-parallax__content').length > 0) {
    new Rellax('.c-parallax__content', {
        center: true,
        vertical: true
    });
}

if(document.querySelector('.s-delivery__foto img')) {
    new Rellax('.s-delivery__foto img', {
        center: true,
        vertical: true
    });
}

if(document.querySelector('.contato__img')) {
    new Rellax('.contato__img', {
        center: true,
        vertical: true
    });
}
