// Redux
import store from './store';
// Controllers
import header from './controllers/header';
import delivery from './controllers/delivery';
import carrinho from './controllers/carrinho';
import steps, { headerCheckout } from './controllers/steps';
import identificacao from './controllers/identificacao';
import contato from './controllers/contato';
import evento from './controllers/evento';
import endereco from './controllers/endereco';
import pagamento from './controllers/pagamento';
import resumo from './controllers/resumo';
import confirmacao from './controllers/confirmacao';
import pedidos from './controllers/pedidos';
import sCardapio from './controllers/s-cardapio';
// import popup from './controllers/popup';
// Utils
import VueRedux from './utils/vue-redux';

import './animation/banner';
// import './animation/sobre';
import './animation/restaurantes';
import './animation/parallax';

const connect = VueRedux(store);

connect(carrinho);
connect(delivery);
connect(header);
connect(steps);
connect(headerCheckout);
connect(identificacao);
connect(endereco);
connect(pagamento);
connect(resumo);
connect(confirmacao);
connect(pedidos);
