import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import VeeValidate from 'vee-validate';
import { nextstep, changeAddress, changeShipping } from '../modules/order';
import { } from '../modules/user';
import { postEndereco, getValorCep, getFretePrevisao } from '../api';
import { alerta } from '../utils/alerta';

Vue.use(VeeValidate, { events: 'change' });
Vue.use(VueTheMask);

const endereco = new Vue({
    el: '#endereco',
    map: state => ({
        step: state.order.step,
        address: state.order.address,
        shipping: state.order.shipping,
        token: state.user.token,
        addresses: state.user.addresses,
        lojas: state.lojas.loja,
    }),
    actions: {
        nextstep,
        changeAddress,
        changeShipping
    },
    data: {
        token: null,
        step: 1,
        addresses : {},
        address : {},
        enviando: false,
        lojas: null
    },
    computed: {
        temEndereco(){
            for(var key in this.addresses) {
                if(this.addresses.hasOwnProperty(key))
                    return true;
            }
            return false;
        }
    },
    methods: {
        onNextStep(){
            this.nextstep();
        },
        validateBeforeSubmit(){
            this.$validator.validateAll().then((result) => {

                if (result) {
                    if(!this.enviando)
                    {
                        this.enviando = true;
                        this.verificaCep(this.salvaEndereco);
                    }
                    return;
                }

                alerta('erro','Preencha todos os campos obrigatórios.');
            });
        },
        verificaCep(callback){
            getValorCep(this.address.cep)
                .then(frete => {
                    this.changeShipping(frete);
                    callback(2);
                })
                .catch(err => {
                    this.changeShipping({});
                    alerta('erro',err.response.data.message);
                    this.enviando = false;
                });
        },
        salvaEndereco(){
            postEndereco(this.address,this.token)
                .then(endereco => {
                    this.changeAddress(endereco);
                    this.fretePrevisao(2);
                    this.enviando = false;
                })
                .catch(err => {
                    alerta('erro',err.response.data.message);
                    this.enviando = false;
                });
        },
        selectAddress(id){
            const endereco = {
                id : id,
                cep : this.addresses[id].cep,
                bairro: this.addresses[id].bairro,
                logradouro: this.addresses[id].logradouro,
                numero: this.addresses[id].numero,
                cidade: this.addresses[id].cidade,
                uf: this.addresses[id].uf,
                complemento: this.addresses[id].complemento,
                referencia: this.addresses[id].referencia
            };

            this.changeAddress(endereco);
            this.verificaCep(this.fretePrevisao);
        },
        retirarNaLoja(id){

            this.changeAddress(this.lojas[id]);

            const newfrete = {
                id: 1,
                titulo: 'Retirar no local',
                valor: 0
            };

            this.changeShipping(newfrete);
            this.fretePrevisao(1);
        },
        fretePrevisao(id){
            getFretePrevisao(id)
                .then(frete => {

                    const newfrete = {
                        id: this.shipping.id,
                        titulo: this.shipping.titulo,
                        valor: this.shipping.valor,
                        aberto: frete.aberto,
                        previsao: frete.previsao
                    };
        
                    this.changeShipping(newfrete);
                    this.nextstep();
                })
                .catch(err => {
                    alerta('erro',err.response.data.message);
                });
        }
    }
});

export default endereco;
