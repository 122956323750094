import Vue from "vue";
import VueTheMask from "vue-the-mask";
import VeeValidate, { Validator } from "vee-validate";
import br from "vee-validate/dist/locale/pt_BR";
import { postContato } from "../api";
import { alerta } from "../utils/alerta";

Vue.use(VeeValidate, { events: 'change' });
Vue.use(VueTheMask);

const dictionary = {
    pt_BR: {
        messages: {
            date_format: () => 'Data inválida.',
            required: () => 'Preenchimento obrigatório.'
        }
    }
};

// altera idioma padrão das mensagens para pt-BR;
Validator.localize('pt_BR', br);
// sobrescreve e concatena as mensagens personalizadas;
Validator.localize(dictionary);

const contato = new Vue({
    el: '#contato',
    data: {
        nome: null,
        email: null,
        telefone: null,
        mensagem: null,
        enviando: false,
    },
    methods: {
        validateBeforeSubmit(submitEvent) {
            this.$validator.validateAll().then((result) => {

                if (result) {
                    if (!this.enviando) {
                        this.enviando = true;
                        const dados = {
                            nome: this.nome,
                            email: this.email,
                            telefone: this.telefone,
                            mensagem: this.mensagem
                        }

                        postContato(dados)
                            .then(res => {
                                this.nome = '';
                                this.email = '';
                                this.telefone = '';
                                this.mensagem = '';
                                this.enviando = false;
                                this.$validator.reset();
                                alerta('sucesso', 'Contato efetuado com sucesso!');
                                this.enviando = false;
                            })
                            .catch(err => {
                                alerta('erro', 'Desculpe, ocorreu um erro, tente novamente mais tarde.');
                                this.enviando = false;
                            });
                    }
                    return;
                }

                alerta('erro', 'Preencha todos os campos obrigatórios.');
            });
        }
    }
});

export default contato;
