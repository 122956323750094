import iziToast from 'izitoast';

export function alerta(tipo, mensagem)
{
    if(tipo == 'sucesso')
    {
        iziToast.show({
            theme: 'dark',
            title: 'Sucesso!',
            message: mensagem,
            color: 'green',
            pauseOnHover: true,
            timeout: 5000,
            position: 'topCenter'
        });
    }

    if(tipo == 'erro')
    {
        iziToast.show({
            theme: 'dark',
            icon: 'ico-error',
            message: mensagem,
            color: 'red',
            pauseOnHover: true,
            timeout: 5000,
            position: 'topCenter'
        });
    }
}
