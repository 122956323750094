export const ADD_LOJAS = 'lojas/ADD_LOJA';
export const RESET_LOJAS = 'lojas/RESET_LOJAS';


const INIT = {
    loja: {}
};

export default (state = INIT, action) => {
    switch(action.type) {
        case ADD_LOJAS:
            return {
                ...state,
                loja: {
                    ...state.loja,
                    [action.id]: action.payload
                }
            };
        case RESET_LOJAS:
            return INIT;
        default:
            return state;
        }
};


export const addLoja = (loja) => ({
    type: ADD_LOJAS,
    id: loja.id,
    payload: loja
});