import Vue from 'vue';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import { changeStep, nextstep, resetOrder, changeShipping } from '../modules/order';
import { resetCart } from '../modules/cart';
import { alerta } from '../utils/alerta';
import { postPedido } from '../api';

const resumo = new Vue({
    el: '#resumo',
    map: state => ({
        products: state.cart.products,
        counter: state.cart.counter,
        user: state.order.user,
        address: state.order.address,
        shipping: state.order.shipping,
        payment: state.order.payment,
        step: state.order.step,
        token: state.user.token,
    }),
    actions: {
        changeShipping,
        nextstep,
        changeStep,
        resetOrder,
        resetCart
    },
    data: {
        token:null,
        step: null,
        products: {},
        user: {},
        address: {},
        shipping: {},
        payment: {}
    },
    computed: {
        subtotal() {
            return reduce(filter(this.products, o => !!o), (subtotal, { preco, preco_promocional, quantidade }) => {
                const precoReal = preco_promocional ? preco_promocional : preco;
                return subtotal + (precoReal * quantidade);
            }, 0.00);
        },
        fullAddress(){
            if(this.address.logradouro) {
                const { logradouro, numero, complemento, bairro, cidade, uf, cep } = this.address;

                return  logradouro+', '+
                        numero+
                        (complemento?' - '+complemento+' - ':', ')+
                        ' '+bairro+
                        (cidade?', '+cidade:'') +
                        (uf?' - '+uf:'') +
                        ', '+cep;
            }
            else
                return '';
        }
    },
    methods: {
        onChangeStep(step) {
            this.changeStep(step);
        },
        finalizarPedido(){

            let carrinho = [];
            Object.values(this.products).forEach(product => {
                carrinho.push({produto_id: product.id, quantidade: product.quantidade, observacao: product.observacao });
            });

            const pedido = {
                carrinho : carrinho,
                endereco_id : this.address.id,
                frete : { id: this.shipping.id },
                pagamento : {tipo:this.payment.id, troco:this.payment.troco, bandeira: [this.payment.cartao]}
            };

            postPedido(pedido,this.token)
                .then(retorno => {

                    const newfrete = {
                        id: this.shipping.id,
                        titulo: this.shipping.titulo,
                        valor: this.shipping.valor,
                        aberto: retorno.data.horario.aberto,
                        previsao: retorno.data.horario.previsao
                    };

                    this.changeShipping(newfrete);
                    this.nextstep();
                    window.location = '/delivery/confirmacao';
                })
                .catch(err => {
                    alerta('erro',err.response.data.message);
                    this.enviando = false;
                });
        }
    }
});

export default resumo;
