import { getProductById } from '../api';

export const ADD_PRODUCT = 'cart/ADD_PRODUCT';
export const REMOVE_PRODUCT = 'cart/REMOVE_PRODUCT';
export const INCREMENT_PRODUCT = 'cart/INCREMENT_PRODUCT';
export const CHANGE_OBSERVATION = 'cart/CHANGE_OBSERVATION';
export const DECREMENT_PRODUCT = 'cart/DECREMENT_PRODUCT';
export const INCREMENT = 'cart/INCREMENT';
export const DECREMENT = 'cart/DECREMENT';
export const RESET_CART = 'cart/RESET_CART';

const INIT = {
    products: {},
    counter: 0
};

export default (state = INIT, action) => {
    switch (action.type) {
        case ADD_PRODUCT:
            return {
                ...state,
                products: {
                    ...state.products,
                    [action.id]: action.payload
                }
            };

        case REMOVE_PRODUCT:
            return {
                ...state,
                counter: state.counter - state.products[action.id].quantidade,
                products: {
                    ...state.products,
                    [action.id]: undefined
                },
            };

        case INCREMENT_PRODUCT:
            return {
                ...state,
                products: {
                    ...state.products,
                    [action.id]: {
                        ...state.products[action.id],
                        quantidade: state.products[action.id].quantidade + 1
                    }
                },
                counter: state.counter + 1
            };

        case CHANGE_OBSERVATION:
            return {
                ...state,
                products: {
                    ...state.products,
                    [action.id]: {
                        ...state.products[action.id],
                        observacao: action.payload.substring(0,188)
                    }
                }
            };

        case DECREMENT_PRODUCT:
            return {
                ...state,
                products: {
                    ...state.products,
                    [action.id]: {
                        ...state.products[action.id],
                        quantidade: state.products[action.id].quantidade - 1
                    }
                },
                counter: state.counter - 1
            };

        case INCREMENT:
            return {
                ...state,
                counter: (state.counter += 1)
            };

        case DECREMENT:
            return {
                ...state,
                counter: (state.counter -= 1)
            };
        case RESET_CART:
            return INIT;
        default:
            return state;
    }
};

export const increment = () => ({ type: INCREMENT });
export const decrement = () => ({ type: DECREMENT });

export const incrementProduct = id => ({ type: INCREMENT_PRODUCT, id });
export const decrementProduct = id => ({ type: DECREMENT_PRODUCT, id });
export const removeProduct = id => ({ type: REMOVE_PRODUCT, id });

export const addProduct = id => (dispatch, getState) => {
    if(getState().cart.products[id]) {
        dispatch(incrementProduct(id));
    } else {
        dispatch(increment());
        getProductById(id)
            .then(produto => {
                dispatch({
                    type: ADD_PRODUCT,
                    id,
                    payload: produto
                });
            })
            .catch(err => console.error(err));
    }
};

export const changeObservation = (id, observation) => ({
    type: CHANGE_OBSERVATION,
    payload: observation, id
});

export const resetCart = () => ({ type: RESET_CART });